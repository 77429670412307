import { useContext } from "react";
import { AuthContext } from "../context/authContext";
import { UserLayout } from "../layouts/User";
import { SectionHead } from "../library/sections/Heading";
import { TodaysList } from "../library/lists/Today";
import { RecentList } from "../library/lists/Recent";
import { ClockInOut } from "../library/widget/ClockInOut";

function Home() {
  const { user } = useContext(AuthContext);

  return (
    <>
      <UserLayout title="Home | Swastik App">
        <section className="mb-8">
          <SectionHead heading="Sessions" />
          <label className="label">
            <span className="label-text font-semibold">
              Upcoming Sessions (next 7 days including today)
            </span>
          </label>
          {user?.data?.entity && <TodaysList id={user?.data?.entity} />}
        </section>
        <section className="mb-8">
          <label className="label">
            <span className="label-text font-semibold">
              Recently Completed Sessions (last 7 days)
            </span>
          </label>
          {user?.data?.entity && <RecentList id={user.data.entity} />}
        </section>
      </UserLayout>
      <ClockInOut />
    </>
  );
}

export default Home;
