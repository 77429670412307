import { useNavigate, useParams } from "react-router-dom";
import { UserLayout } from "../layouts/User";
import { SectionHead } from "../library/sections/Heading";
import { SessionDetails } from "../library/details/Session";
import { RosterTable } from "../library/table/Roster";
import { SESSION_DETAILS } from "../utils/queries";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { CommentsList } from "../library/lists/Comments";
import { AssessmentsList } from "../library/lists/Assessments";

function Session() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data } = useQuery(SESSION_DETAILS, {
    variables: { sessionId: id },
    onError: (error) => {
      console.error(error);
      toast.error("Unable to get details!");
    },
  });

  return (
    <UserLayout title="Session Details">
      <section>
        <SectionHead heading="Session Details">
          <button className="btn btn-outline" onClick={() => navigate(-1)}>
            Back
          </button>
        </SectionHead>
        <div className="flex flex-col gap-8">
          {data && data.details && (
            <SessionDetails
              details={{
                name: data.details.name,
                class: data.details.class,
                training: data.details.training,
                workshop: data.details.workshop,
                date: data.details.date,
                time: data.details.time,
                teacher: data.details.teacher,
                assistant: data.details.assistant,
                duration: data.details.duration,
                status: data.details.status,
                rosterCount: data.details.rosterCount,
              }}
            />
          )}
          {data && data.details && <AssessmentsList entity="Session" id={id} />}
          {data && data.details && <RosterTable sessionId={id} />}
        </div>
      </section>
      <section>
        <div className="px-2 py-6">
          <h3 className="font-medium mb-4 label-text">Comments</h3>
          <CommentsList type="Class" id={id} commenting={true} />
        </div>
      </section>
    </UserLayout>
  );
}

export default Session;
