import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  ChevronRightIcon,
  ChatBubbleLeftEllipsisIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import toast from "react-hot-toast";
import { LIST_SESSIONS } from "../../utils/queries";
import { toDDMMYYYY } from "../../utils/helpers";

export const RecentList = (props) => {
  const { id } = props;

  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1))
    .toISOString()
    .split("T")[0];
  const lastWeek = new Date(new Date().setDate(new Date().getDate() - 7))
    .toISOString()
    .split("T")[0];

  const { loading, data } = useQuery(LIST_SESSIONS, {
    variables: {
      teacherId: id,
      from: lastWeek,
      to: yesterday,
      status: ["COMPLETE"],
    },
    onError: (error) => {
      console.error(error);
      toast.error("Unable to get recent sessions");
    },
    pollInterval: 50000,
  });

  if (loading || !data) return <></>;

  return (
    <>
      <div className="divide-y-2 bg-white rounded-2xl shadow-md">
        {data?.list?.total > 0 &&
          data?.list?.sessions?.map((value) => (
            <div key={value.id}>
              <Link to={`/session/${value.id}`}>
                <div className="flex">
                  <div className="flex flex-col gap-2 md:flex-row md:justify-between py-4 pl-8 pr-3 flex-1">
                    <div className="flex flex-col space-y-2">
                      <div className="flex flex-row gap-4 items-center">
                        <h3 className="text-xl font-semibold">
                          {value.class?.name ??
                            value.training?.name ??
                            value.workshop?.name ??
                            "-"}
                        </h3>
                        {/* comments count badge */}
                        <div className="flex flex-row items-center">
                          <div className="bg-gray-100 rounded-full px-2">
                            <p className="text-gray-600 text-sm flex items-center gap-1">
                              <ChatBubbleLeftEllipsisIcon className="h-4 w-4" />{" "}
                              <span className="font-semibold font-mono">
                                {value.commentsCountStaff
                                  ? value.commentsCountStaff
                                  : 0}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <p>
                        <span className="font-semibold">
                          {value.name} | {value.time}
                        </span>
                      </p>
                      <p>{toDDMMYYYY(value.date)}</p>
                    </div>
                    <div className="flex flex-row items-start md:flex-col md:items-end md:justify-center">
                      <span
                        className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                          value.status
                        )}`}
                      >
                        {value.status}
                      </span>
                    </div>
                  </div>
                  <div className="flex mr-4 items-center flex-shrink-0">
                    {value.assessmentsCount === 0 && (
                      <ExclamationTriangleIcon className="h-5 w-5 text-warning" />
                    )}
                    <ChevronRightIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </Link>
            </div>
          ))}
      </div>
      {data?.list.total === 0 && (
        <div className="py-8">
          <div className="text-center">
            <h3 className="mt-2 text-md font-medium text-gray-900">
              No sessions
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              No recently completed sessions within last 7 days. <br />
            </p>
          </div>
        </div>
      )}
    </>
  );
};

function statusTagColor(str) {
  switch (str) {
    case "UPCOMING":
      return `bg-warning`;
    case "ONGOING":
      return `bg-info`;
    default:
      return `bg-base-300`;
  }
}
