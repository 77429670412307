import { useContext } from "react";
import { AuthContext } from "../../context/authContext";
import { AssessmentModal } from "../modals/Assessment";
import { AssessmentForm } from "../forms/Assessment";
import { convertUnixTimestamp } from "../../utils/helpers";
import { useMutation, useQuery } from "@apollo/client";
import { REMOVE_ASSESSMENT } from "../../utils/mutations";
import { LIST_ASSESSMENTS, SESSION_DETAILS } from "../../utils/queries";
import toast from "react-hot-toast";

export const AssessmentsList = (props) => {
  const { entity, id } = props;

  const { user } = useContext(AuthContext);

  const { data } = useQuery(LIST_ASSESSMENTS, {
    variables: { entity, ref: id },
    onError: (error) => {
      console.error(error);
      toast.error("Unable to get assessments!");
    },
  });

  const [removeAssessment] = useMutation(REMOVE_ASSESSMENT, {
    onCompleted: () => {
      toast.success("Assessment removed successfully!");
    },
    onError: () => {
      toast.error("Unable to remove assessment!");
    },
    refetchQueries: [
      {
        query: SESSION_DETAILS,
        variables: { sessionId: id },
      },
      { query: LIST_ASSESSMENTS, variables: { entity, ref: id } },
    ],
  });

  const handleRemove = (id) => {
    window.confirm("Are you sure you want to delete this assessment?") &&
      removeAssessment({
        variables: {
          id: id,
        },
      });
  };

  return (
    <>
      {data?.list?.length > 0 ? (
        <div className="bg-white px-4 rounded-lg mb-6 shadow-md divide-y">
          {data?.list?.map((assessment) => {
            return (
              <div
                className="flex flex-col md:flex-row gap-2 items-start py-4"
                key={assessment.id}
              >
                <div className="basis-full">
                  <h4 className="font-semibold">
                    {assessment.from.userId === user.data.id
                      ? "My assessment"
                      : `Assessment from ${assessment.from.name}`}
                  </h4>
                  <p className="whitespace-pre-line">{assessment.text}</p>
                  <small>{convertUnixTimestamp(assessment.updated)}</small>
                </div>
                {assessment.from.userId === user.data.id && (
                  <div className="flex flex-row gap-2">
                    <AssessmentModal
                      title="Update Assessment"
                      content="Update assessment for this session"
                      btn="btn btn-xs btn-outline"
                      label="Update"
                    >
                      <AssessmentForm
                        id={assessment.id}
                        entityId={id}
                        text={assessment.text}
                        entity={entity}
                      />
                    </AssessmentModal>
                    <button
                      className="btn btn-xs btn-outline"
                      onClick={() => handleRemove(assessment.id)}
                    >
                      &#x2715;
                    </button>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div>
          <div className="flex flex-col justify-center items-center gap-4">
            <AssessmentModal
              title="Add Assessment"
              content="Add assessment for this session"
              btn="btn btn-primary btn-outline"
              label="Add Assessment"
            >
              <AssessmentForm type="Session" entityId={id} entity={entity} />
            </AssessmentModal>
          </div>
        </div>
      )}
    </>
  );
};
