import { useState, useEffect, useContext } from "react";
import toast from "react-hot-toast";
import moment from "moment";
import clsx from "clsx";
import { AuthContext } from "../../context/authContext";
import { haversine } from "../../utils/helpers";
import { TimesheetModal } from "../modals/Timesheet";
import { useQuery, useMutation } from "@apollo/client";
import { ACTIVE_TIMESHEET, LIST_LOCATIONS } from "../../utils/queries";
import { CREATE_TIMESHEET, UPDATE_TIMESHEET } from "../../utils/mutations";

export const ClockInOut = () => {
  const { user } = useContext(AuthContext);

  const [error, setError] = useState(null);
  const [myCoords, setMyCoords] = useState(null);
  const [nearby, setNearby] = useState(false);
  const [clockedIn, setClockedIn] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [duration, setDuration] = useState(null);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setMyCoords({ lat: latitude, lng: longitude });
        },
        (err) => {
          toast.error(err.message);
          setError("Location permission required for timesheet entry.");
          console.error(err);
        }
      );
    } else {
      toast.error("Geolocation is not supported by this browser");
    }
  };

  // check distance between myCoords and locations and return the closest location
  const closestLocation = (coords, locations = []) => {
    if (!coords || locations.length === 0) return null;

    const distances = locations.map((loc) => {
      const distance = haversine(
        {
          latitude: coords.lat,
          longitude: coords.lng,
        },
        {
          latitude: loc.coords.lat,
          longitude: loc.coords.lng,
        }
      ).toFixed(2);
      return { ...loc, distance: parseFloat(distance) };
    });

    const closest = distances.reduce((prev, curr) =>
      prev.distance < curr.distance ? prev : curr
    );

    if (closest.distance <= 50) return closest;
    else return null;
  };

  const { loading: fetching } = useQuery(LIST_LOCATIONS, {
    onCompleted: (data) => {
      if (data.locations?.length > 0) {
        // filter locations with coords.lat and coords.lng
        const mappedLocations = data.locations.filter(
          (loc) => loc.coords.lat && loc.coords.lng
        );
        const closest = closestLocation(myCoords, mappedLocations);

        if (closest) setNearby(true);
        else setNearby(false);
      }
    },
    onError: (err) => {
      console.error(err);
      toast.error("Failed to fetch locations");
    },
  });

  const { refetch, loading: checking } = useQuery(ACTIVE_TIMESHEET, {
    onCompleted: (data) => {
      if (data.timesheet) {
        setClockedIn(data.timesheet);
      } else setClockedIn(null);
    },
    onError: (err) => {
      console.error(err);
      toast.error("Failed to fetch active timesheet");
    },
    variables: { userId: user.data.id },
    fetchPolicy: "network-only",
  });

  const [clockIn, { loading: clockingIn }] = useMutation(CREATE_TIMESHEET, {
    onCompleted: (data) => {
      if (data.created) {
        toast.success("Clocked in successfully");
        refetch();
      }
    },
    onError: (err) => {
      console.error(err);
      toast.error("Failed to create timesheet");
    },
  });

  const [clockOut, { loading: clockingOut }] = useMutation(UPDATE_TIMESHEET, {
    onCompleted: (data) => {
      if (data.updated) {
        toast.success("Clocked out successfully");
        refetch();
      }
    },
    onError: (err) => {
      console.error(err);
      toast.error("Failed to update timesheet");
    },
  });

  const handleClockIn = () => {
    const input = {
      userId: user.data.id,
      start: new Date().toISOString(),
    };

    clockIn({ variables: { input } });
  };

  const handleClockOut = () => {
    if (clockedIn == null) return;

    const input = {
      id: clockedIn.id,
      end: new Date().toISOString(),
      userId: user.data.id,
    };

    clockOut({ variables: { input } });
  };

  // update current time every second
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  // get location and refresh every 5 minutes
  useEffect(() => {
    getLocation();

    const intervalId = setInterval(getLocation, 300000); // 5 minutes (300,000 ms)

    return () => clearInterval(intervalId);
  }, []);

  // set duration if clocked in
  useEffect(() => {
    if (clockedIn) {
      const start = moment(clockedIn.start);
      const end = moment(new Date());
      const duration = moment.duration(end.diff(start));
      setDuration(duration);

      const intervalId = setInterval(() => {
        const end = moment(new Date());
        const duration = moment.duration(end.diff(start));
        setDuration(duration);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [clockedIn]);

  return (
    <section className="fixed flex justify-center px-4 pt-4 w-full bottom-0">
      <div className="max-w-sm w-full max-h-80 bg-white p-4 rounded-t-xl shadow-lg drop-shadow-2xl">
        <div className="p-1">
          <h2 className="text-sm text-gray-600 tracking-wide font-bold text-center uppercase">
            Timesheet Entry
          </h2>
        </div>
        {error && (
          <p className="w-full p-2 text-sm text-red-500 text-center">{error}</p>
        )}
        <div className="pt-2">
          {/* if clocked in */}
          {!clockedIn && (
            <TimesheetModal
              label="Clock In"
              title="Clock In"
              content="Are you sure you want to clock in?"
              btn={clsx(
                "btn w-full btn-primary",
                fetching || (checking && "btn-loading")
              )}
            >
              <div className="text-center border-t pt-4">
                <small className="block pb-2 font-semibold">
                  <span>Current Time: </span>
                  {currentTime.toLocaleTimeString("en-AU", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </small>
                <button
                  onClick={handleClockIn}
                  className={clsx(
                    "btn w-full btn-primary",
                    clockingIn && "btn-loading"
                  )}
                  disabled={!nearby || clockingIn}
                >
                  Clock In
                </button>
              </div>
            </TimesheetModal>
          )}
          {/* if clocked out */}
          {clockedIn && (
            <TimesheetModal
              label="Clock Out"
              title="Clock Out"
              content="Are you sure you want to clock out?"
              btn="btn w-full btn-primary btn-outline"
            >
              <div className="text-center border-t pt-4">
                {duration && (
                  <small className="block pb-2 font-semibold">
                    <span>Duration: </span>
                    {duration.hours()}h {duration.minutes()}m{" "}
                    {duration.seconds()}s
                  </small>
                )}
                <button
                  onClick={handleClockOut}
                  className={clsx(
                    "btn w-full btn-primary",
                    clockingOut && "btn-loading"
                  )}
                  disabled={clockingOut}
                >
                  Clock Out
                </button>
              </div>
            </TimesheetModal>
          )}
        </div>
      </div>
    </section>
  );
};
