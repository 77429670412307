import { gql } from "@apollo/client";

export const PROFILE_INFO = gql`
  query ProfileInfo {
    info: profileInfo {
      id
      email
      phone
      street
      suburb
      postcode
    }
  }
`;

export const LIST_SESSIONS = gql`
  query Sessions(
    $teacherId: ID
    $from: String
    $to: String
    $status: [String]
    $limit: Int
    $page: Int
  ) {
    list: sessions(
      teacherId: $teacherId
      from: $from
      to: $to
      status: $status
      limit: $limit
      page: $page
    ) {
      total
      sessions {
        id
        name
        date
        start
        end
        time
        duration
        class {
          id
          name
        }
        training {
          id
          name
        }
        workshop {
          id
          name
        }
        teacher {
          id
          name
        }
        assistant {
          id
          name
        }
        hall {
          id
          name
        }
        conflict
        status
        assessmentsCount
      }
    }
  }
`;

export const SESSION_DETAILS = gql`
  query Session($sessionId: ID) {
    details: session(sessionId: $sessionId) {
      id
      name
      date
      time
      duration
      start
      end
      conflict
      class {
        id
        name
      }
      hire {
        id
        name
      }
      training {
        id
        name
      }
      workshop {
        id
        name
      }
      teacher {
        id
        name
      }
      assistant {
        id
        name
      }
      location {
        id
        name
      }
      hall {
        id
        name
      }
      status
      rosterCount
      # roster {
      #   id
      #   name
      #   attended
      #   studentId
      #   studentCommentsStaff
      # }
      # assessments {
      #   id
      #   from {
      #     id
      #     userId
      #     name
      #   }
      #   text
      #   created
      #   updated
      # }
    }
  }
`;

export const LIST_ROSTER = gql`
  query ListRoster(
    $sessionId: ID
    $studentId: ID
    $enrolmentId: ID
    $passId: ID
    $admissionId: ID
    $termId: ID
  ) {
    roster: listRoster(
      sessionId: $sessionId
      studentId: $studentId
      enrolmentId: $enrolmentId
      passId: $passId
      admissionId: $admissionId
      termId: $termId
    ) {
      id
      student {
        name
        picture
      }
      attended
      session {
        id
        name
        date
        time
        start
        end
        status
        class {
          id
          name
        }
        workshop {
          id
          name
        }
      }
    }
  }
`;

export const STUDENT_PROFILE = gql`
  query StudentProfileDetails($studentId: ID!) {
    profile: studentProfileDetails(studentId: $studentId) {
      student {
        id
        firstName
        middleName
        lastName
        gender
        dob
        picture
        notes
      }
      customer {
        id
        email
        phone
        street
        suburb
        postcode
      }
    }
  }
`;

export const LIST_COMMENTS = gql`
  query ListComments($entity: String, $ref: String) {
    list: listComments(entity: $entity, ref: $ref) {
      id
      to
      visibility
      text
      flagged
      updated
      from {
        id
        username
        email
      }
    }
  }
`;

export const LIST_ASSESSMENTS = gql`
  query ListAssessments($entity: String, $ref: String) {
    list: listAssessments(entity: $entity, ref: $ref) {
      id
      text
      from {
        id
        name
        type
        userId
      }
      ref
      entity
      created
      updated
    }
  }
`;

export const READ_COMMENT = gql`
  query Comment($id: ID) {
    comment(commentId: $id) {
      id
      to
      visibility
      text
      flagged
      updated
      from {
        id
        username
        email
      }
    }
  }
`;

export const ACTIVE_TIMESHEET = gql`
  query ActiveTimesheet($userId: ID, $teacherId: ID) {
    timesheet: activeTimesheet(userId: $userId, teacherId: $teacherId) {
      id
      start
    }
  }
`;

export const LIST_LOCATIONS = gql`
  query ListLocations {
    locations: listLocations {
      id
      name
      coords {
        lng
        lat
      }
    }
  }
`;
