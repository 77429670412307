import moment from "moment";

export const convertUnixTimestamp = (datetimeString) => {
  // convert unix timestamp to milliseconds and then create a new Date object
  const date = new Date(datetimeString * 1);
  // Split the datetimeString into date and time parts
  const parsedDatetime = moment(date);
  const isoDateTime = parsedDatetime.format("DD-MM-YYYY hh:mm A");

  return isoDateTime;
};

export const haversine = (coords1, coords2) => {
  const { latitude: lat1, longitude: lon1 } = coords1;
  const { latitude: lat2, longitude: lon2 } = coords2;

  const R = 6371e3; // metres
  const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // in metres
};

export const toDDMMYYYY = (date) => {
  return moment(date).format("DD-MM-YYYY");
};
