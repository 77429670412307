import { NotificationModal } from "../modals/Notification";
import { NotificationForm } from "../forms/Notification";
import { toDDMMYYYY } from "../../utils/helpers";

export const SessionDetails = (props) => {
  const { details } = props;

  return (
    <>
      {details && (
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 py-6 px-8 my-6 bg-white rounded-lg shadow-md">
          <div>
            <dt className="p-1 pb-0">
              <span className="label-text font-semibold">Name</span>
            </dt>
            <dd className="p-1">
              <span className="mt-1 text-md">{details.name}</span>
            </dd>
          </div>

          {details.class && (
            <div>
              <dt className="p-1 pb-0">
                <span className="label-text font-semibold">Class</span>
              </dt>
              <dd className="p-1 flex flex-row items-center">
                <span className="mt-1 text-md">
                  {details.class.name ?? "-"}
                </span>
                {details.rosterCount > 0 && (
                  <NotificationModal
                    title="Send a Class Notification"
                    content="Send a notification to all students in this class (includes all students who are enrolled as of date)"
                    btn="ml-4 btn btn-xs btn-outline"
                    label="Notify Class"
                  >
                    <NotificationForm type="class" id={details.class.id} />
                  </NotificationModal>
                )}
              </dd>
            </div>
          )}

          {details.training && (
            <div>
              <dt className="p-1 pb-0">
                <span className="label-text font-semibold">Training</span>
              </dt>
              <dd className="p-1 flex flex-row items-center">
                <span className="mt-1 text-md">
                  {details.training.name ?? "-"}
                </span>
              </dd>
            </div>
          )}

          {details.workshop && (
            <div>
              <dt className="p-1 pb-0">
                <span className="label-text font-semibold">Workshop</span>
              </dt>
              <dd className="p-1 flex flex-row items-center">
                <span className="mt-1 text-md">
                  {details.workshop.name ?? "-"}
                </span>
              </dd>
            </div>
          )}

          <div>
            <dt className="p-1 pb-0">
              <span className="label-text font-semibold">Date</span>
            </dt>
            <dd className="p-1">
              <span className="mt-1 text-md">{toDDMMYYYY(details.date)}</span>
            </dd>
          </div>

          <div>
            <dt className="p-1 pb-0">
              <span className="label-text font-semibold">Time</span>
            </dt>
            <dd className="p-1">
              <span className="mt-1 text-md">{details.time}</span>
            </dd>
          </div>

          <div>
            <dt className="p-1 pb-0">
              <span className="label-text font-semibold">Teacher</span>
            </dt>
            <dd className="p-1">
              <span className="mt-1 text-md">
                {details.teacher?.name ?? "-"}
              </span>
            </dd>
          </div>

          <div>
            <dt className="p-1 pb-0">
              <span className="label-text font-semibold">Assistant</span>
            </dt>
            <dd className="p-1">
              <span className="mt-1 text-md">
                {details.assistant?.name ?? "-"}
              </span>
            </dd>
          </div>

          <div>
            <dt className="p-1 pb-0">
              <span className="label-text font-semibold">Duration</span>
            </dt>
            <dd className="p-1">
              <span className="mt-1 text-md">
                {details.duration > 0 ? `${details.duration} mins` : "-"}
              </span>
            </dd>
          </div>

          <div>
            <dt className="p-1 pb-0">
              <span className="label-text font-semibold">Status</span>
            </dt>
            <dd className="p-1">
              <span
                className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                  details.status
                )}`}
              >
                {details.status}
              </span>
            </dd>
          </div>
        </div>
      )}
    </>
  );
};

function statusTagColor(str) {
  switch (str) {
    case "ONGOING":
      return `bg-info`;
    case "UPCOMING":
      return `bg-warning`;
    default:
      return `bg-base-300`;
  }
}
